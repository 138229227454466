import React from 'react';
import { Navigate } from 'react-router-dom';

type ProtectedRouteProps = {
  isLoggedIn: boolean;
  children: JSX.Element;
  role?: string;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isLoggedIn, children, role }) => {
  if(role && role !== "ADMIN")
  	return <Navigate to="/" />;

  if (!isLoggedIn) {
    // Redirect to the login page or home page
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
