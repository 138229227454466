import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import '../../css/HamburgerMenu.css'; // Make sure to create this CSS file

type HamburgerMenuProps = {
    role?: string;
    firstName?: string;
    lastName?:string;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({role, firstName, lastName}) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    
    const toggleLogout = () => {
    	localStorage.removeItem('isLoggedIn');
	localStorage.removeItem('userData');
	window.location.href = '/';
    };

    const toggleProfileSettings = () => {
	navigate('/user');
    }

    const toggleUploadQuestion = () => {
	navigate('/upload');
	}
    const toggleApproveQuestion = () => {
	navigate('/approve');
	}

    return (
        <div>
            <div className="hamburger-icon" onClick={toggleMenu}>
                <div />
                <div />
                <div />
            </div>
            {isOpen && (
                <div className="menu">
		<p>{firstName} {lastName}</p>
                {role && (
                    <ul>
                        <li><button className="menu-button"><a href="/">Home</a></button></li>
			<li><button onClick={toggleProfileSettings}  className="menu-button">Profile</button></li>
                        {role !== "STUDENT" && (
	                        <React.Fragment>
        	                	{/*<li><a href="/create">Create Exam</a></li>*/}
        	                </React.Fragment>
                        )}
                        {role === "ADMIN" && (
	                        <React.Fragment>
        	                	<li><button onClick={toggleUploadQuestion} className="menu-button">Add Question</button></li>
        	                	<li><button onClick={toggleApproveQuestion} className="menu-button">Approve Question</button></li>
        	                </React.Fragment>
                        )}
                        <li><button className="menu-button" onClick={toggleLogout}>Logout</button></li>
                    </ul>
                 )}
                </div>
            )}
        </div>
    );
};

export default HamburgerMenu;
