import React from 'react';
import {useNavigate} from 'react-router-dom';
import Profile from '../Profile';
import LoginForm from '../tools/LoginForm';
import {UserData} from '../types/types';

type ProfileWrapperProps = {
    isLoggedIn: boolean;
    onLoginSuccess: (storedData: string) => void;
    data: UserData | null;
}

const ProfileWrapper: React.FC<ProfileWrapperProps> = ({isLoggedIn, onLoginSuccess, data}) => {
	const navigate = useNavigate();
	return (
	<div>
	    {isLoggedIn ? (
		<Profile navigate={navigate} data={data} />
	    ) : (
	    <LoginForm onLoginSuccess={onLoginSuccess} navigate={navigate} />
	    )}
	</div>
	);
};

export default ProfileWrapper;
