import React from 'react';
import {NavigateFunction} from 'react-router';
import HamburgerMenu from './tools/HamburgerMenu';
import {UserData} from './types/types';
import TeacherProfile from './TeacherProfile';
import StudentProfile from './StudentProfile'

type ProfileProps = {
    navigate: NavigateFunction;
    data: UserData | null;
};

const Profile: React.FC<ProfileProps> = ({navigate, data}) => {
    return (
	<div>
	    <HamburgerMenu role={data?.role} firstName={data?.first_name} lastName={data?.last_name} />
	    { data && data.role === 'STUDENT' ? (
		    <StudentProfile navigate={navigate} studentData={data}/>
	    ) : (
	    <TeacherProfile teacherID={data?.userID} />
	    )
	    }
	</div>
	);
}


export default Profile;
