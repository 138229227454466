import React, { useState } from 'react';
import {NavigateFunction} from 'react-router-dom';
import WarningMessage from './WarningMessage';
import axios, {AxiosError} from 'axios';
import '../../css/LoginForm.css';
import { API_URL } from '../../config';

type FormData = {
  username: string;
  password: string;
};

type LoginFormProps = {

    onLoginSuccess: (storedData: string) => void;
    navigate: NavigateFunction;
};

const LoginForm: React.FC<LoginFormProps> = ({onLoginSuccess, navigate}) => {
  const [formData, setFormData] = useState<FormData>({ username: '', password: '' });
  const [warningMessage, setWarningMessage] = useState('');
  const [warningKey, setWarningKey] = useState(0);
  
  const handleRegister = () => {
 	console.log("Register Button");
 	navigate('/register');
  }
  
  interface ErrorResponse {
  	error: string;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      console.log(`${API_URL}/login`);
      const response = await axios.post(`${API_URL}/login`, formData);
//      const response = await axios.post(`${API_URL}/test`);
      console.log(response.data.message);
      // Handle further logic (like storing the user role, redirection, etc.)
      console.log(response.data.userData);
      onLoginSuccess(JSON.stringify(response.data.userData));
    } catch (error) {
      console.error('Login failed', error);
      // Handle error (like displaying an error message)
      let errorMessage = 'Login failed. Please try a valid username and/or password';
      
      if(axios.isAxiosError(error)){
      	//Type assertion for the error response data
      	const serverError = error as AxiosError<ErrorResponse>;
	if (serverError.response && serverError.response.data) {
		errorMessage = serverError.response.data.error || errorMessage;
	}      
      }
      setWarningMessage(errorMessage);
      setWarningKey(prevKey => prevKey + 1);
     }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
  <div className="login-form-container">
  {warningMessage && <WarningMessage message={warningMessage} key={warningKey} />}
    <form onSubmit={handleSubmit} className="login-form">
      <input
        type="text"
        name="username"
        value={formData.username}
        onChange={handleChange}
        placeholder="Username"
        required
      />
      <input
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        placeholder="Password"
        required
      />
      <button type="submit">Login</button>
      <button onClick={handleRegister}>Register</button>
    </form>
    </div>
  );
};

export default LoginForm;

