import React, { useState } from 'react';
import {NavigateFunction} from 'react-router-dom';
import HamburgerMenu from './tools/HamburgerMenu';
import SuccessMessage from './tools/SuccessMessage'
import WarningMessage from './tools/WarningMessage'
import { UserData } from './types/types';
import axios from 'axios';
import '../css/UserSettings.css'
import { API_URL } from '../config';

type UserSettingsProps = {
  navigate: NavigateFunction;
  onLoginSuccess: (storedData: string) => void;
  data: UserData | null;
};

const UserSettings: React.FC<UserSettingsProps> = ({ navigate, data, onLoginSuccess}) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [successKey, setSuccessKey] = useState(0);
  const [warningMessage, setWarningMessage] = useState('');
  const [warningKey, setWarningKey] = useState(0);

  const [isEditing, setIsEditing] = useState(false);
  const [username, setUsername] = useState(data?.username || '');
  const [email, setEmail] = useState(data?.email || '');
  const [firstName, setFirstName] = useState(data?.first_name || '');
  const [lastName, setLastName] = useState(data?.last_name || '');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const storedUserData = localStorage.getItem('userData');
  const initialUserData = storedUserData ? JSON.parse(storedUserData) : null;
  const [dataX, setData] = useState<UserData | null>(initialUserData);

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    // Construct the payload
    const payload = {
      id: data?.userID, // Assuming you have the user's ID in your data
      username,
      email,
      firstName,
      lastName,
      oldPassword,
      newPassword,
    };
    
    const urlUpdate = `${API_URL}/update_user`
    
    

    axios.post(urlUpdate, payload)
    .then(response => {
      console.log("yay");
      setSuccessMessage(response.data.message);
      setSuccessKey(successKey + 1);
      
      const getUser = `${API_URL}/get_user`
      const payloadUser = {
      	id:data?.userID,
      }
      
      axios.post(getUser, payloadUser)
      .then(resp => {
      	onLoginSuccess(JSON.stringify(resp.data.userData));
      	setIsEditing(false);
      })
      .catch(error => {
      	const errorData = error.response ? error.response.data : { error: 'An unknown error occurred' };
      	console.error(errorData.error);
      })
      
      
      // Optionally navigate away or clear form
      // Example: navigate('/user/profile'); // Assuming you're using react-router-dom
    })
    .catch(error => {
      // Handle server errors or validation messages
      // Axios wraps the response under error.response
      const errorData = error.response ? error.response.data : { error: 'An unknown error occurred' };
      setWarningMessage(errorData.error);
      setWarningKey(warningKey + 1);
      console.error(errorData.error);
    });
  };

if(isEditing){
  return (
    <div className="user-settings-container">
    <HamburgerMenu role={dataX?.role} firstName={dataX?.first_name} lastName={dataX?.last_name} />
    {successMessage && <SuccessMessage key={successKey} message={successMessage} />}
    {warningMessage && <WarningMessage key={warningKey} message={warningMessage} />}
      <h1 className="user-settings-header">User Settings</h1>
      <form className="user-settings-form" onSubmit={handleUpdate}>
        <div>
          <label>Username:</label>
          <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div>
          <label>First Name:</label>
          <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
        </div>
        <div>
          <label>Last Name:</label>
          <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
        </div>
        <div>
          <label>Old Password (for password change):</label>
          <input type="password" value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
        </div>
        <div>
          <label>New Password:</label>
          <input type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
        </div>
        <button onClick={() => setIsEditing(false)}>Cancel Edit</button>
        <button type="submit">Update</button>
      </form>
    </div>
  );
  } else {
      return (
      <div>
      <HamburgerMenu role={data?.role} firstName={data?.first_name} lastName={data?.last_name} />
    {successMessage && <SuccessMessage key={successKey} message={successMessage} />}
    {warningMessage && <WarningMessage key={warningKey} message={warningMessage} />}

      <div className="user-settings-display">
        <h1>User Settings</h1>
        <div>Username: {data?.username}</div>
        <div>Email: {data?.email}</div>
        <div>First Name: {data?.first_name}</div>
        <div>Last Name: {data?.last_name}</div>
        <button onClick={() => setIsEditing(true)}>Edit</button>
      </div>
      </div>
    );
  }
  	
};

export default UserSettings;

