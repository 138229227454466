import {useLocation, useNavigate} from 'react-router-dom';
import {UserData} from '../types/types';
import QuestionForm from '../tools/QuestionForm';

type QuestionFormWrapperProps = {
  data: UserData | null;
};

const QuestionFormWrapper: React.FC<QuestionFormWrapperProps> = ({data}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const {} = location.state || {};

    return (
        <QuestionForm navigate={navigate} data={data}/>
    );
};

export default QuestionFormWrapper;
